import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { parseQueryString } from 'helpers/url'
import { getCookie } from 'services/cookies'
import { DynamicImport } from '../../DynamicImport'

export const UnAuthRoute = ({
  component: Component,
  load,
  reducers,
  history,
  ...rest
}) => {
  const authenticated = getCookie(process.env.REACT_APP_AUTH_TOKEN_COOKIE)

  return (
    <Route
      {...rest}
      render={props => {
        if (
          !authenticated ||
          rest.sessions.login.isLoading ||
          rest.sessions.login.completed ||
          rest.sessions.authy.completed ||
          rest.sessions.authy.isLoading
        ) {
          if (Component) {
            return <Component {...props} />
          }

          if (load) {
            return <DynamicImport load={load} reducers={reducers} {...props} />
          }
        }

        const objQS = parseQueryString(history.location.search) || {}
        const redirectPath = objQS.redirectRoute || '/account'

        return <Redirect to={redirectPath} />
      }}
    />
  )
}

UnAuthRoute.defaultProps = {
  history: {},
  load: undefined,
  reducers: []
}

UnAuthRoute.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  load: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  reducers: PropTypes.arrayOf(PropTypes.any)
}
