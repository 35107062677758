import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { COLORS } from 'COLORS'
import { CollapseIconV1 as CollapseIcon } from 'icons/CollapseIcon/CollapseIcon'

const Styles = styled.div`
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  padding: 5px 10px 5px 5px;
  margin-top: -15px;
  margin-bottom: 15px;
  width: fit-content;
  border-radius: 2px;
  &:hover {
    ${props =>
      props.enableHoverStyle !== false &&
      `
      background-color: ${COLORS.grey200};
    `}
  }
  display: flex;
  align-items: center;
  transition: all 150ms ease;
`

const BackButton = props => {
  const {
    history,
    linkText,
    path,
    className,
    iconColor,
    enableHoverStyle,
    onClick,
    dataId
  } = props

  const clickHandler = onClick || (() => history?.push(path))

  return (
    <Styles
      onClick={clickHandler}
      className={className}
      enableHoverStyle={enableHoverStyle}
      data-id={dataId}>
      <CollapseIcon
        width="13px"
        height="13px"
        viewBox="0 0 26 26"
        color={iconColor}
      />
      <div>{linkText}</div>
    </Styles>
  )
}

BackButton.defaultProps = {
  className: '',
  enableHoverStyle: true,
  iconColor: COLORS.grey400,
  history: null,
  path: null,
  onClick: null,
  dataId: ''
}

BackButton.propTypes = {
  linkText: PropTypes.string.isRequired,
  history: PropTypes.objectOf(PropTypes.any),
  path: PropTypes.string,
  onClick: PropTypes.func,
  enableHoverStyle: PropTypes.bool,
  iconColor: PropTypes.string,
  className: PropTypes.string,
  dataId: PropTypes.string
}

export { BackButton }
