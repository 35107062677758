import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { TEXT_STYLES } from 'TEXT_STYLES'
import { AddToGroupDropdownForm } from 'ui_elements/AddToGroupDropdownForm/AddToGroupDropdownForm'
import { ButtonType, Button, Icon, Popover } from '@junglescout/edna'

const StyledIcon = styled(Icon)`
  margin-right: 5px;
`

const BackButton = styled(Button)`
  min-width: unset;
  padding-left: 13px;
  margin-right: auto;
`

const DropdownTitle = styled.div`
  ${TEXT_STYLES.BodyBlack};
  color: ${props => props.theme.colors.blackOpacity40};
  margin: 0px 12px 8px 12px;
`
const DropdownWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 4px;
`
const DropdownItems = styled.div`
  overflow: auto;
  max-height: 173px;
`
const DropdownItem = styled.div`
  cursor: pointer;
  padding: 4px 12px;
  margin-bottom: 8px;

  &:hover {
    color: ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.primary100};
  }
`
const Separator = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 8px 0;

  padding: 0 8px;

  color: ${props => props.theme.colors.grey500};
  font-size: 11px;

  &::after {
    content: '';
    background-color: ${props => props.theme.colors.grey300};
    height: 1px;
    width: 100%;
  }
`
const StyledAddToGroupDropdownForm = styled(AddToGroupDropdownForm)`
  padding: 0 12px;
`

export const AddGroupDropdown = ({
  className,
  isOpen,
  setIsOpen,
  onCreateAndAdd,
  createButtonText,
  onCategorySelected,
  children
}) => {
  const { t } = useTranslation(['product_database'])

  let trackerCategories = useSelector(
    state => state.globalData.trackerCategories
  )

  trackerCategories = trackerCategories.filter(category => {
    return !['Ungrouped', 'Uncategorized'].includes(category.name)
  })

  const hasMultipleUsers = useSelector(
    state => state.globalData.user.has_multiple_users
  )

  const { isLoading, completed } = useSelector(
    state => state.tracker.createCategory
  )

  const [showForm, setShowForm] = useState(true)
  const [isPrivateCategory, setIsPrivateCategory] = useState(!hasMultipleUsers)
  const [inputCategoryName, setInputCategoryName] = useState('')

  const title = showForm
    ? t(
        'product_database:DatabaseTable.modalAddTitle',
        'Add to Product Tracker'
      )
    : t(
        'product_database:DatabaseTable.modalSelectTitle',
        'Add To Existing Group'
      )

  const toggleForm = () => {
    if (showForm) {
      setInputCategoryName('')
    }

    setShowForm(prev => !prev)
  }

  const handlePrivateCategoryChanged = ({ value }) => {
    setIsPrivateCategory(value)
  }

  const renderContent = () => {
    if (showForm) {
      return (
        <StyledAddToGroupDropdownForm
          isBackButtonVisible={false}
          createButtonType={ButtonType.PRIMARY}
          createButtonText={createButtonText}
          renderToggle
          toggleForm={toggleForm}
          handleSubmit={() =>
            onCreateAndAdd(inputCategoryName, isPrivateCategory)
          }
          completed={completed}
          handleInputChange={({ value }) => setInputCategoryName(value)}
          handleToggleChange={handlePrivateCategoryChanged}
          isLoading={isLoading}
          label={t('product_database:DatabaseTable.modalLabel', 'Group Name')}
          value={inputCategoryName}
          hasMultipleUsers={hasMultipleUsers}
          sharingEnabled
          isGroupPrivate={{ isPrivate: isPrivateCategory }}
        />
      )
    }

    return (
      <DropdownItems>
        {trackerCategories.map(category => {
          return (
            <DropdownItem
              key={category.name}
              onClick={() => {
                onCategorySelected(category.name)
                setIsOpen(false)
              }}>
              {category.name}
            </DropdownItem>
          )
        })}
      </DropdownItems>
    )
  }

  const renderPopoverContent = () => {
    return (
      <DropdownWrapper>
        <DropdownTitle>{title}</DropdownTitle>
        {renderContent()}
        {showForm ? (
          <>
            <Separator>
              {t('product_database:DatabaseTable.separatorText', 'or')}
            </Separator>
            <Button onClick={toggleForm} btnType={ButtonType.TERTIARY}>
              <StyledIcon name="PLUS_SQUARE" />
              {t(
                'product_database:DatabaseTable.modalExistingTitle',
                'Add to Existing Group'
              )}
            </Button>
          </>
        ) : (
          <BackButton onClick={toggleForm} btnType={ButtonType.SECONDARY_TEXT}>
            <StyledIcon name="CHEVRON_LEFT" />
            {t('product_database:DatabaseTable.modalBackTitle', 'Back')}
          </BackButton>
        )}
      </DropdownWrapper>
    )
  }

  return (
    <Popover
      width="300px"
      className={className}
      content={renderPopoverContent()}
      open={isOpen}
      onOpenChange={() => isOpen && setIsOpen(false)}
      side="bottom"
      align="start">
      {children}
    </Popover>
  )
}

AddGroupDropdown.defaultProps = {
  className: undefined,
  setIsOpen: () => {}
}

AddGroupDropdown.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func,
  createButtonText: PropTypes.string.isRequired,
  onCreateAndAdd: PropTypes.func.isRequired,
  onCategorySelected: PropTypes.func.isRequired
}
