import React from 'react'
import PropTypes from 'prop-types'

import { isFeatureEnabled } from '../../../helpers/features'
import { FourZeroFourPage } from '../../FourZeroFourPage'
import { AuthRoute } from './AuthRoute/AuthRoute'
import LoadingPage from '../LoadingPage'

const AccessRoute = ({ hasAccess, ...routeProps }) => {
  const { feature, globalData } = routeProps
  const { flagData, initialLoad } = globalData
  const hasFeatureAccess = !feature || isFeatureEnabled(feature, flagData)

  if (hasFeatureAccess && hasAccess) {
    return <AuthRoute {...routeProps} />
  }

  // if no globalData is loaded yet, we want to display LoadingPage to avoid 404 page flash
  if (initialLoad?.isLoading) {
    return <AuthRoute component={LoadingPage} {...routeProps} />
  }

  const { render, ...notFoundprops } = routeProps // eslint-disable-line no-unused-vars

  return <AuthRoute component={FourZeroFourPage} {...notFoundprops} />
}

AccessRoute.propTypes = {
  globalData: PropTypes.object.isRequired,
  feature: PropTypes.string,
  hasAccess: PropTypes.bool.isRequired
}

export default AccessRoute
