import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'

import { CollapseIconV1 as CollapseIcon } from 'icons/CollapseIcon/CollapseIcon'

import { Button, ButtonType, ToggleButton } from '@junglescout/edna'
import { TextInput } from 'ui_elements/TextInput/TextInput'
import { FormLabel } from 'ui_elements/TextInput/FormLabel'
import { QuestionTooltip } from 'ui_elements/QuestionTooltip/QuestionTooltip'

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 5px;
  button {
    margin-right: 0;
    margin-top: 8px;
  }
`

const NameLabel = styled(FormLabel)`
  font-size: 13px;
  font-weight: 500;
`

const InputField = styled(TextInput)`
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  height: 35px;
  min-height: 35px;
`

const BackButton = styled(Button)`
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 0;
  min-width: fit-content;
  &:hover {
    background-color: ${props => props.theme.colors.grey050};
  }
  display: flex;
`
const FormWrapper = styled.div`
  padding: 0 10px 10px;
`

const ShareToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 5px;
  font-size: 13px !important;
  gap: 8px;
`

const ShareLabel = styled.span`
  display: flex;
`
class AddToGroupDropdownForm extends Component {
  componentDidMount() {
    this.debouncedOnChange = debounce(this.debouncedOnChange, 500)

    if (this.props.renderToggle) {
      this.props.handleToggleChange({ prop: 'isPrivate', value: true })
    }
  }

  handleKeyPress = async e => {
    const { handleSubmit, value } = this.props

    if (e.key === 'Enter' && value) {
      await handleSubmit()
    }
  }

  onInputChange = e => {
    const { valueProp } = this.props

    this.debouncedOnChange({ prop: valueProp, value: e.target.value })
  }

  debouncedOnChange = payload => {
    const { handleInputChange } = this.props

    handleInputChange(payload)
  }

  renderShareGroupToggle = () => {
    if (this.props.renderToggle) {
      //   //don't render toggle for supplier for now. There has to be a cleanup after remaining components are done
      const {
        hasMultipleUsers,
        isGroupPrivate: { isPrivate: checked },
        sharingEnabled,
        t
      } = this.props
      if (sharingEnabled && hasMultipleUsers) {
        return (
          <ShareToggleWrapper>
            <ShareLabel>
              {t(
                'common:CreateNewGroupModal.toggleLabel',
                'Share with my team'
              )}
              <QuestionTooltip
                content={
                  <span>
                    {t(
                      'common:CreateNewGroupModal.toggleLabelTooltip',
                      'This will allow your team to edit and delete this group'
                    )}
                  </span>
                }
                margin="0 0 0 2px"
              />
            </ShareLabel>
            <ToggleButton
              checked={!checked}
              handleToggleClick={() => {
                this.props.handleToggleChange({
                  prop: 'isPrivate',
                  value: !checked
                })
              }}
            />
          </ShareToggleWrapper>
        )
      }
    }
  }

  render() {
    const {
      className,
      completed,
      handleSubmit,
      isBackButtonVisible,
      isLoading,
      label,
      toggleForm,
      value,
      createButtonType,
      createButtonText,
      createButtonDataId
    } = this.props

    return (
      <FormWrapper className={className}>
        <NameLabel>{label}</NameLabel>
        <InputField
          placeholder="Please Enter"
          onChange={this.onInputChange}
          onKeyPress={this.handleKeyPress}
          type="text"
          autoFocus
        />
        {this.renderShareGroupToggle()}
        <Buttons>
          <Button
            disabled={isEmpty(value)}
            isLoading={isLoading}
            completed={completed}
            btnType={createButtonType}
            onClick={handleSubmit}
            dataId={createButtonDataId}>
            {createButtonText}
          </Button>
          {isBackButtonVisible && (
            <BackButton
              btnType={ButtonType.SECONDARY_TEXT}
              onClick={toggleForm}>
              <CollapseIcon width="13px" height="13px" viewBox="0 0 26 26" />
              <div>Back</div>
            </BackButton>
          )}
        </Buttons>
      </FormWrapper>
    )
  }
}

AddToGroupDropdownForm.defaultProps = {
  isBackButtonVisible: true,
  valueProp: 'name',
  createButtonType: ButtonType.PRIMARY_TEXT,
  createButtonText: 'Create & Add'
}

AddToGroupDropdownForm.propTypes = {
  completed: PropTypes.bool,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isBackButtonVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  toggleForm: PropTypes.func.isRequired,
  value: PropTypes.string,
  valueProp: PropTypes.string,
  createButtonType: PropTypes.string,
  createButtonText: PropTypes.string
}

const wrappedComponent = withTranslation('common')(AddToGroupDropdownForm)
export { wrappedComponent as AddToGroupDropdownForm }
