import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'
import { useSelector } from 'react-redux'
import { ClickableIcon, Tooltip } from '@junglescout/edna'

import { sendProductInAmazonViewed } from 'helpers/segment'

const Wrapper = styled.div`
  display: inline-block;
`

function AmazonLink(props) {
  const {
    t,
    url,
    tooltip,
    dataId,
    className,
    onClick,
    disabled,
    product,
    hideTooltip
  } = props

  const defaultTooltip = t('generic:ViewOnAmazon', 'View on Amazon')
  const appType = useSelector(state => state.globalData.appType)
  const { colors } = useTheme()

  const onClickHandler = e => {
    e.preventDefault()
    sendProductInAmazonViewed(product, appType)
    return onClick?.(e) || null
  }

  return (
    <Wrapper className={className} data-id={dataId}>
      <Tooltip
        disabled={hideTooltip}
        content={<div>{tooltip || defaultTooltip}</div>}
        side="bottom">
        <div>
          <ClickableIcon
            ariaLabel={defaultTooltip}
            color={disabled ? colors.grey300 : colors.grey700}
            disabled={disabled}
            name="AMAZON_CIRCLE"
            onClick={e => {
              onClickHandler(e)
              return disabled ? null : window.open(url, '_blank', 'noreferrer')
            }}
          />
        </div>
      </Tooltip>
    </Wrapper>
  )
}

AmazonLink.defaultProps = {
  disabled: false,
  hideTooltip: false,
  tooltip: '',
  dataId: '',
  className: undefined,
  onClick: undefined,
  product: undefined,
  t: () => {}
}

AmazonLink.propTypes = {
  t: PropTypes.func,
  url: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  dataId: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  hideTooltip: PropTypes.bool,
  product: PropTypes.objectOf(PropTypes.any)
}

const wrappedComponent = withTranslation('generic')(AmazonLink)
export { wrappedComponent as AmazonLink }
