import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthRoute } from '../AuthRoute/AuthRoute'

const Empty = () => <div />

export const AdminRoute = ({ component: Component, ...props }) => {
  if (props.globalData.initialLoad.isLoading) {
    return <Route path="/" component={Empty} />
  }

  if (
    !props.globalData.employeeWithAccess ||
    props.globalData.isImpersonating
  ) {
    return <Redirect to={props.sessions.locationHistory} />
  }

  if (process.env.NODE_ENV === 'production' && !props.globalData.hasAuthy) {
    props.createNotification({
      message: 'Please enable authy to access the admin panel',
      level: 'error',
      title: 'Two-Factor Authentication Required'
    })
    return <Redirect to="/account/info" />
  }

  return <AuthRoute {...props} component={Component} />
}
